// Dependencias
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

// Componentes
import CloseBtn from './CloseBtn';
import InputSearch from './InputSearch';
import ButtonSubmit from './ButtonSubmit';
import { getConfiguracion } from './api';

// Contexto
import { ParametrosBusquedaProvider } from './parametrosBusqueda';

// Datos
import { datosEntrada } from './constans';
import { language } from './language';

import {Liferay as LiferayAPI} from './common/services/liferay/liferay';
import apicommon from './common/services/liferay/apicommon';

import Observer from './Observer';

import * as customDatepicker from './datepicker.js';

const MotorBusqueda = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const MotorBusqueda = useRef();
  const [urlTheme, setUrlTheme] = useState('');
  const [urlFlight, setUrlFlight] = useState('');
  const [datepicker, setDatepicker] = useState('');

	useEffect(() => {
		async function getUrlTheme() {
			var newUrlTheme = await getConfiguracion("url-theme");
			setUrlTheme(newUrlTheme);
		};
		
		async function getUrlFlight() {
			var newUrlFlight = Liferay.ThemeDisplay.getLanguageId().split("_")[0];
			setUrlFlight(newUrlFlight);
		};
		
		if(!urlTheme)
			getUrlTheme();
			
		if(!urlFlight)
			getUrlFlight();
	}, []);
	
	useEffect(() => {
				async function getDatepicker() {
					var language = {
					    "Enero": {
					      "es_ES": "Enero",
					      "en_US": "January",
					      "de_DE": "Januar"
					    },
					    "Febrero": {
					      "es_ES": "Febrero",
					      "en_US": "February",
					      "de_DE": "Februar"
					    },
					    "Marzo": {
					      "es_ES": "Marzo",
					      "en_US": "March",
					      "de_DE": "Marsch"
					    },
					    "Abril": {
					      "es_ES": "Abril",
					      "en_US": "April",
					      "de_DE": "April"
					    },
					    "Mayo": {
					      "es_ES": "Mayo",
					      "en_US": "May",
					      "de_DE": "Mai"
					    },
					    "Junio": {
					      "es_ES": "Junio",
					      "en_US": "June",
					      "de_DE": "Juni"
					    },
					    "Julio": {
					      "es_ES": "Julio",
					      "en_US": "July",
					      "de_DE": "Juli"
					    },
					    "Agosto": {
					      "es_ES": "Agosto",
					      "en_US": "August",
					      "de_DE": "August"
					    },
					    "Septiembre": {
					      "es_ES": "Septiembre",
					      "en_US": "September",
					      "de_DE": "September"
					    },
					    "Octubre": {
					      "es_ES": "Octubre",
					      "en_US": "October",
					      "de_DE": "Oktober"
					    },
					    "Noviembre": {
					      "es_ES": "Noviembre",
					      "en_US": "November",
					      "de_DE": "November"
					    },
					    "Diciembre": {
					      "es_ES": "Diciembre",
					      "en_US": "December",
					      "de_DE": "Dezember"
					    },
					    "Lunes": {
					      "es_ES": "Lunes",
					      "en_US": "Monday",
					      "de_DE": "Montag"
					    },
					    "Martes": {
					      "es_ES": "Martes",
					      "en_US": "Tuesday",
					      "de_DE": "Dienstag"
					    },
					    "Miércoles": {
					      "es_ES": "Miércoles",
					      "en_US": "Wednesday",
					      "de_DE": "Mittwoch"
					    },
					    "Jueves": {
					      "es_ES": "Jueves",
					      "en_US": "Thursday",
					      "de_DE": "Donnerstag"
					    },
					    "Viernes": {
					      "es_ES": "Viernes",
					      "en_US": "Friday",
					      "de_DE": "Freitag"
					    },
					    "Sábado": {
					      "es_ES": "Sábado",
					      "en_US": "Saturday",
					      "de_DE": "Samstag"
					    },
					    "Domingo": {
					      "es_ES": "Domingo",
					      "en_US": "Sunday",
					      "de_DE": "Sonntag"
					    },
					    "confirmar-fechas": {
					      "es_ES": "Confirmar fechas",
					      "en_US": "Confirm dates",
					      "de_DE": "Termine bestätigen"
					    }
					  }
					  
					  const $e = customDatepicker.be(customDatepicker.ke);
					    var se = {};
					    Object.defineProperty(se, "__esModule", { value: !0 });
					    var de = (se.default = void 0),
					    Ce = {
					      days: [
					        language["Domingo"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Lunes"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Martes"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Miércoles"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Jueves"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Viernes"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Sábado"][Liferay.ThemeDisplay.getLanguageId()],
					      ],
					      daysShort: [language["Domingo"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					       language["Lunes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					       language["Martes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3), 
					       language["Miércoles"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					       language["Jueves"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					       language["Viernes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					       language["Sábado"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3)],
					      daysMin: [language["Domingo"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2),
					       language["Lunes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2),
					       language["Martes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2), 
					       language["Miércoles"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2),
					       language["Jueves"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2),
					       language["Viernes"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2),
					       language["Sábado"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 2)],
					      months: [
					        language["Enero"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Febrero"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Marzo"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Abril"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Mayo"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Junio"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Julio"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Agosto"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Septiembre"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Octubre"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Noviembre"][Liferay.ThemeDisplay.getLanguageId()],
					        language["Diciembre"][Liferay.ThemeDisplay.getLanguageId()],
					      ],
					      monthsShort: [
					        language["Enero"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Febrero"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Marzo"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Abril"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Mayo"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Junio"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Julio"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Agosto"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Septiembre"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Octubre"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Noviembre"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3),
					        language["Diciembre"][Liferay.ThemeDisplay.getLanguageId()].substring(0, 3)
					      ],
					      today: "Hoy",
					      clear: "Limpiar",
					      dateFormat: "dd/MM/yyyy",
					      timeFormat: "hh:mm aa",
					      firstDay: 1,
					    };
					    de = se.default = Ce;
					new $e("#date-range", {
					    classes: "range-picker-reserve",
					    locale: de,
					    range: !0,
					    multipleDatesSeparator: " - ",
					    container: "#bookingSearch",
					    autoClose: !1,
					    onRenderCell: function(d, type) {
						    if (d.cellType == 'day') {
							  var disabled = false;
						          
						      if(new Date(d.date) < new Date((new Date()).valueOf() - 1000*3600*24)) {
								  return {
						          	disabled: true
						          }
						      } else {
						      	return {disabled: disabled}
						      }
						    }
					    },
					    inline: !1,
					    navTitles: { days: "<strong>MMMM</strong>" },
					    buttons: [
					      {
					        content: language["confirmar-fechas"][Liferay.ThemeDisplay.getLanguageId()],
					        className: "btn-picker-custom",
					        onClick: (y) => {
					          y.hide();
					        },
					      },
					    ],
					    toggleSelected: !1,
					    onShow: () => {
					      const y = document.querySelector(".range-picker-reserve"),
					        L = document.getElementById("dateRangeInput");
					      L &&
					        y &&
					        ((y.style.width = `${L.clientWidth}px`),
					        (y.style.left = `${L.offsetLeft}px`));
					    },
					  });
					setDatepicker('datepicker');
				};
					
				if(!datepicker)
					getDatepicker();
			}, []);
	
  /**
   * Funci�n para cerrar el motor de b�squeda
   */
  function openSearch() {
    setIsSearchOpen(true);
  }	

  /**
   * Funci�n para cerrar el motor de b�squeda
   */
  function closeSearch() {
    setIsSearchOpen(false);
  }

  /**
     * Funci�n que maneja el submit del formulario
     */
  function handleSubmit(e) {
    e.preventDefault();
  }

  // Ocultar el motor de b�squeda al llegar al final de la p�gina
  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (!isSearchOpen) {
        if ((window.scrollY + window.innerHeight) >= (document.body.clientHeight - 200)) {
          MotorBusqueda.current?.classList.contains('hidden') ? null : MotorBusqueda.current.classList.add('hidden');
        } else {
          MotorBusqueda.current?.classList.contains('hidden') ? MotorBusqueda.current.classList.remove('hidden') : null;
        }
      }
    })
  })
  
  function openBookingtravel() {
  	window.open('https://bookingtravel.hotelsviva.com/' + urlFlight + "/");
  }
  
  function showExclusive(e) {
	e.preventDefault();
	$("#exclusive_content").css({
	    visibility: "visible",
	    opacity: "1",
	});
  }
  
  function closeExclusive() {
	$("#exclusive_content").css({
	    visibility: "hidden",
	    opacity: "0",
	});
  }
  
  function openMotor() {
		if (window.innerWidth > 768) {
			$("#bookingSearch").addClass("activeSearch");
		    $("#overalySearch").css({
		      visibility: "visible",
		      opacity: "0.8",
		    });
		    $("#closeOverlay").css({
		      visibility: "visible",
		    });
		}
	}
	
	function closeMotor() {
		$("#bookingSearch").removeClass("activeSearch");
		$("#overalySearch").css({
		    visibility: "hidden",
		    opacity: "0",
		});
		$("#closeOverlay").css({
		    visibility: "hidden",
		});
	}
	
	function showMobile() {
		$(".booking").css({
		    height: "100vh",
		    display: "block",
		    position: "fixed",
		    padding: "1.5rem"
		});

		$(".booking_content").css({
		    display: "block",
		});

		$(".btnBookingMobile").css({
		    display: "none",
		});
	}
	
	function closeMobile() {
		$("#bookingSearch").css("display", "none");
		$("#overalySearch").css({
		    display: "none",
		});
		$(".btnBookingMobile").css({
		    display: "block",
		});
		$("#closeOverlay").css({
		    display: "none",
		});
	}


  return (
    <ParametrosBusquedaProvider>
      <div class="bgBooking">
        <div class="bgBooking_Search" id="overalySearch" style={{visibility: 'hidden'}} onClick={closeMotor}></div>
        <a href="#" id="closeOverlay" style={{visibility: 'hidden'}} onClick={closeMotor}>
            <img src={urlTheme + "/assets/icons/uil-multiply.svg"} alt="" />
        </a>
      </div>
      <div className={isSearchOpen ? "booking activeSearch" : "booking"} id="bookingSearch" ref={MotorBusqueda} onClick={openMotor}>
        <div className="booking_content">
          <div class="mobileTitle">
                <p>{language["haz-tu-reserva"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                <a href="#" id="closeContentMobile" onClick={closeMobile}>
                    <img src={urlTheme + "/assets/icons/uil-times-circle.svg"} alt=""/>
                </a>
          </div>
          <div class="booking_content-Links">
                <div class="hotelVuelos">
                    <a href="#">{language["hotel"][Liferay.ThemeDisplay.getLanguageId()]}</a>
                    <a class="vueloPlus" href="#" onClick={openBookingtravel}><span class="icon iconVuelo"></span>{language["vuelo-hotel"][Liferay.ThemeDisplay.getLanguageId()]}</a>
                </div>
                <div class="exclusive">
                    <a id="showExclusive" href="#" onClick={(e) => showExclusive(e)}>{language["disfruta-ventajas-exclusivas"][Liferay.ThemeDisplay.getLanguageId()]} <span class="icon iconPlus"></span></a>

                    <div id="exclusive_content" class="exclusive_content">
                        <div class="exclusive_content-col">
                            <div class="exclusive_content-col--img">
                                <img loading="lazy" src={urlTheme + "/assets/icons/Hand.svg"} alt=""/>
                            </div>
                            <div class="exclusive_content-col--txt">
                                <p>{language["mejor-precio-online"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                            </div>
                        </div>
                        <div class="exclusive_content-col">
                            <div class="exclusive_content-col--img">
                                <img loading="lazy" src={urlTheme + "/assets/icons/discountExclusive.svg"} alt="" />
                            </div>
                            <div class="exclusive_content-col--txt">
                                <p>{language["descuento-proximas-estancias"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                            </div>
                        </div>
                        <div class="exclusive_content-col">
                            <div class="exclusive_content-col--img">
                                <img loading="lazy" src={urlTheme + "/assets/icons/VIVA.svg"} alt=""/>
                            </div>
                            <div class="exclusive_content-col--txt">
                                <p>{language["ventajas-programas-fidelizacion"][Liferay.ThemeDisplay.getLanguageId()]}</p>
                            </div>
                        </div>
                        <a href="#" id="closeOverlayExclusive" onClick={closeExclusive}>
                            <img src={urlTheme + "/assets/icons/uil-times-circle.svg"} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
          <div className="form-row">
            {datosEntrada.map((dato) => <InputSearch key={dato.id} isSearchOpen={isSearchOpen} dato={dato} />)}
            <ButtonSubmit submitAction={handleSubmit} />
          </div>
        </div>
      </div>
      <div className="btnBookingMobile">
      	<a className="btn btn-booking" id="btnBookingMobile" href="#" onClick={showMobile}>{language["reservar"][Liferay.ThemeDisplay.getLanguageId()]}</a>
      </div> 
    </ParametrosBusquedaProvider>
  )
};

class WebComponent extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		
				createRoot(this).render(
					<MotorBusqueda
					/>,
					this
				);
	}
}

const ELEMENT_ID = 'liferay-hoteles-viva-motor-reserva';

if (!customElements.get(ELEMENT_ID)) {
	customElements.define(ELEMENT_ID, WebComponent);
}
